<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="info && info.assignmentnumber">
            <v-btn v-if="role != 'worker'" style="position: absolute; z-index: 1; right: 10px" color="primary" outlined
              @click="invoiceDialog = true">Rechnung erstellen</v-btn>
            <v-list-item>
              <v-list-item-avatar color="primary" class="headline font-weight-light white--text">A</v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="info.name"></v-list-item-title>
                <v-list-item-subtitle v-text="info.assignmentnumber"></v-list-item-subtitle>
                <v-list-item-subtitle>{{ info.customername }} ({{
            info.standortname
          }})</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-alert type="warning" :value="assignmentClosed">Diese Auftrag wurde bereits übermittelt und kann nicht mehr
            verändert werden.</v-alert>
          <v-tabs v-model="tab" background-color="grey lighten-2">
            <v-tab key="info">Informationen</v-tab>
            <v-tab key="an">Arbeitsnachweise ({{ arbeitsnachweise.length }})</v-tab>
            <v-tab key="wartungen">Wartungen ({{ wartungen.length }})</v-tab>
            <v-tab key="docs">Dokumente ({{ documents.length }})</v-tab>
            <!--    <v-tab key="summary">Rechnung</v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="tab" class="full-width ma-2">
            <v-tab-item key="info">
              <v-col cols="12" sm="12" md="12" lg="8" xl="6" v-if="role === 'worker'">
                <v-card class="mx-auto" flat v-if="info">
                  <v-card-title>Auftrag</v-card-title>
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <td>
                                <b>Auftragsnummer</b>
                              </td>
                              <td>{{ info.assignmentnumber }}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Kundenname</b>
                              </td>
                              <td>{{ info.customername }}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Standortbezeichnung</b>
                              </td>
                              <td>{{ info.standortname }}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Adresse</b>
                              </td>
                              <td v-if="info.street">
                                {{ info.street }} {{ info.houseno }},
                                {{ info.zip }} {{ info.city }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Kommentar</b>
                              </td>
                              <td>{{ info.comment }}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Projektleiter</b>
                              </td>
                              <td>{{ info.plnames }}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Mitarbeiter</b>
                              </td>
                              <td>{{ info.usernames }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" xl="6" v-else>
                <v-card class="mx-auto" flat>
                  <v-card-title>Auftrag
                    <v-chip v-if="info.status == '0'" color="#9c27b0" dark class="ml-5">Erstellt</v-chip>
                    <v-chip v-if="info.status == '1'" color="orange" dark class="ml-5">In Bearbeitung</v-chip>
                    <v-chip v-if="info.status == '2'" color="error" class="ml-5">Übermittelt</v-chip>
                    <v-chip v-if="info.status == '3'" color="success" class="ml-5">Abgeschlossen</v-chip>
                  </v-card-title>
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-form ref="form">
                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field v-model="info.date" label="Datum*" readonly v-on="on" required
                              :rules="[rules.required]"></v-text-field>
                          </template>
                          <v-date-picker v-model="info.date" no-title first-day-of-week="1" scrollable
                            @input="menu = false"></v-date-picker>
                        </v-menu>
                        <v-text-field label="Auftragsnummer" v-model="info.assignmentnumber"
                          :readonly="role === 'worker'"></v-text-field>
                        <v-select v-model="info.plids" :items="leiters" item-value="id" item-text="name"
                          label="Projektleiter*" multiple :readonly="role === 'worker'" required
                          :rules="[rules.requiredarray]"></v-select>
                        <v-select multiple v-model="info.userids" :items="users" item-text="name"
                          :readonly="role === 'worker'" item-value="id" label="Mitarbeiter"></v-select>
                        <v-row>
                          <v-col xs12 sm6 md6>
                            <v-select label="Kunde*" v-model="info.customerid" item-text="name" item-value="id"
                              :readonly="!showCustomersToRole" :items="customers" @change="changeCustomer()" required
                              :rules="[rules.required]"></v-select>
                          </v-col>
                          <v-col xs12 sm6 md6>
                            <v-select v-model="info.standortid" item-text="name" item-value="id"
                              :readonly="!showCustomersToRole" :items="standorte" :disabled="!standorte.length"
                              label="Standort" required :rules="[rules.required]"></v-select>
                          </v-col>
                        </v-row>
                        <v-select outlined :items="stati" item-value="id" item-text="text" v-model="info.status"
                          :readonly="role === 'worker'" label="Status"></v-select>
                        <!-- <v-text-field
                      v-if="!showCustomersToRole"
                      v-model="info.customername"
                      label="Kundenname*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-if="!showCustomersToRole"
                      v-model="info.address"
                      label="Adresse*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field> -->
                        <v-textarea label="Kommentar" outlined v-model="info.comment"
                          :readonly="role === 'worker'"></v-textarea>
                      </v-form>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" xl="6" style="display: flex; justify-content: space-between">
                <v-btn color="success" v-if="role != 'worker'" @click="updateData()">Auftrag aktualisieren</v-btn>
                <v-btn :disabled="parseInt(info.status) == 3 && role != 'worker'" v-if="role != 'worker'"
                  color="primary" @click="checkStatus()">Auftrag abschließen</v-btn>

                <v-btn v-if="!assignmentClosed && role == 'worker'" color="primary" @click="changeStatus(2)">Auftrag
                  Übermitteln</v-btn>
              </v-col>
            </v-tab-item>
            <v-tab-item key="an">
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-data-table :headers="headersarbeitsnachweise" height="inherit" hide-default-footer
                  class="row-pointer" @click:row="handleClick2" :items-per-page="-1" :items="arbeitsnachweise">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Arbeitsnachweise ({{
            arbeitsnachweise.length
          }})</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-tooltip left v-if="!assignmentClosed">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="$router.push('/arbeitsnachweis/new/' + id)" fab color="success">
                            <i class="material-icons">add</i>
                          </v-btn>
                        </template>
                        <span>Neuen Arbeitsnachweis anlegen</span>
                      </v-tooltip>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <span v-if="item.status == '0'" class="orange-highlight">Erstellt</span>
                    <span v-if="item.status == '1'" class="orange-highlight">In Bearbeitung</span>
                    <span v-if="item.status == '2'" class="darkred-highlight">Abgeschlossen</span>
                    <span v-if="item.status == '3'" class="green-highlight">Abgeschlossen</span>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="handleClick2(item)" fab small color="primary">
                          <v-icon>mdi-playlist-edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Arbeitsnachweis bearbeiten</span>
                    </v-tooltip>
                    <v-tooltip left v-if="role !== 'worker'">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="printNachweis(item.id, item.number)" fab small color="primary">
                          <i class="material-icons">print</i>
                        </v-btn>
                      </template>
                      <span>Arbeitsnachweis drucken</span>
                    </v-tooltip>
                    <v-tooltip left v-if="role !== 'worker' ||
            (item.created_by === userid && item.status != '2')
            ">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click.stop="
            confirmDeleteItem(item.id, item.number, 'an')
            " color="error" small fab>
                          <i class="material-icons">delete</i>
                        </v-btn>
                      </template>
                      <span>Auftrag löschen</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.created="{ item }">
                    <div>{{ item.createdname ? item.createdname : "-" }}</div>
                    <div>{{ $formatDateTime(item.created_at) }}</div>
                  </template>
                  <template v-slot:item.updated="{ item }">
                    <div>{{ item.updatedname ? item.updatedname : "-" }}</div>
                    <div>{{ $formatDateTime(item.updated_at) }}</div>
                  </template>
                </v-data-table>
              </v-col>
              <!--  <v-col cols="12" sm="12" md="12" lg="12">
                <v-data-table
                  :headers="headersmaengel"
                  height="inherit"
                  hide-default-footer
                  :items-per-page="-1"
                  :items="maengel"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title
                        >Mängel ({{ maengel.length }})</v-toolbar-title
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="$router.push('/anlage/new/' + id)"
                        fab
                        color="success"
                      >
                        <i class="material-icons">add</i>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="
                              $router.push(
                                '/anlage/edit/' + item.id + '/-1/' + id
                              )
                            "
                            color="primary"
                            small
                            fab
                          >
                            <i class="material-icons">settings</i>
                          </v-btn>
                        </template>
                        <span>Anlage bearbeiten</span>
                      </v-tooltip>

                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="$router.push('/anlagehistory/' + item.id)"
                            color="primary"
                            small
                            fab
                          >
                            <i class="material-icons">alarm_on</i>
                          </v-btn>
                        </template>
                        <span>Anlagenhistorie anzeigen</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="duplicateItem(item)"
                            color="primary"
                            small
                            fab
                          >
                            <i class="material-icons">filter_1</i>
                          </v-btn>
                        </template>
                        <span>Anlagen duplizieren</span>
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="
                              confirmDeleteItem(item.id, item.name, 'anlage')
                            "
                            color="error"
                            small
                            fab
                          >
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Anlage löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:item.timestamps="{ item }">
                    <small
                      >Erstellt am: {{ $formatDate(item.created_at) }}</small
                    >
                    <br />
                    <small
                      >Aktualsiert am: {{ $formatDate(item.updated_at) }}</small
                    >
                  </template>
                </v-data-table>
              </v-col> -->
            </v-tab-item>
            <v-tab-item key="wartungen">
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-data-table :headers="headerswartungen" height="inherit" class="row-pointer" @click:row="handleClick"
                  hide-default-footer :items-per-page="-1" :items="wartungen">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Wartungen ({{ wartungen.length }})</v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.status="{ item }">

                    <v-chip v-if="item.status == '0'" color="#9c27b0" dark class="ml-5">Erstellt</v-chip>
                    <v-chip v-if="item.status == '1'" color="orange" dark class="ml-5">In Bearbeitung</v-chip>
                    <v-chip v-if="item.status == '2'" color="error" class="ml-5">Übermittelt</v-chip>
                    <v-chip v-if="item.status == '3'" color="success" class="ml-5">Abgeschlossen</v-chip>

                    <!--   <span v-if="item.status == '0'" class="orange-highlight"
                      >Erstellt</span
                    >
                    <span v-if="item.status == '1'" class="orange-highlight"
                      >In Bearbeitung</span
                    >
                    <span v-if="item.status == '2'" class="darkred-highlight"
                      >Übermittelt</span
                    >
                    <span v-if="item.status == '3'" class="green-highlight"
                      >Abgeschlossen</span
                    > -->
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="handleClick(item)" fab small color="primary">
                          <i class="material-icons">playlist_add_check</i>
                        </v-btn>
                      </template>
                      <span>Wartungsdetatils anzeigen</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.finalized="{ item }">
                    <div>{{ $formatDateTime(item.finalized) }}</div>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <div>{{ $formatCurrency(item.price) }}</div>
                  </template>
                </v-data-table>
              </v-col>
            </v-tab-item>

            <v-tab-item key="docs">
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-data-table :headers="headersdocuments" height="inherit" hide-default-footer :items-per-page="-1"
                  :items="documents">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Dokumente ({{ documents.length }})</v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.created_at="{ item }">{{
            $formatDateTime(item.created_at)
          }}</template>
                  <template v-slot:item.path="{ item }">
                    <div class="link" @click="$openDoc(item)">
                      {{ item.filename }}
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="
            confirmDeleteItem(item.id, item.number, 'doc');
          deletePath = item.path;
          " color="error" small fab>
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Dokument löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:footer>
                    <hr class="my-5" />
                    <v-row>
                      <v-col cols="12" sm="9" md="9" lg="9">
                        <v-file-input v-model="docs" color="primary accent-4" counter label="Dokumente" multiple
                          placeholder="Hier klicken um Dokumente hochzuladen" prepend-icon="mdi-paperclip" outlined
                          :show-size="1000"></v-file-input>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3">
                        <v-btn @click="uploadDocs()" color="primary" :disabled="docs.length === 0 || !online">Dokumente
                          Hochladen</v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-dialog v-model="invoiceDialog" persistent>
        <v-card>
          <v-card-title class="headline">Rechnung erstellen</v-card-title>
          <v-card-text>
            <v-data-table :headers="headerssummary" height="inherit" hide-default-footer v-model="selected" show-select
              item-key="id" :items-per-page="-1" :items="summaryitems">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Rechnung</v-toolbar-title>
                </v-toolbar>
              </template>
              <!--   <template v-slot:item.material="{ item }">
                    <div v-html="item.material"></div>
                  </template>
                  <template v-slot:item.hours="{ item }">
                    <div v-html="item.hours"></div>
                  </template>
                  <template v-slot:item.materialprice="{ item }">
                    <div>{{ $formatCurrency(item.materialprice) }}</div>
                  </template>
                  <template v-slot:item.hoursprice="{ item }">
                    <div>{{ $formatCurrency(item.hoursprice) }}</div>
                  </template> -->
              <template v-slot:item.price="{ item }">
                <div>
                  {{ $formatCurrency(item.price) }}
                </div>
              </template>
              <template v-slot:item.items="{ item }">
                <div v-html="item.items"></div>
              </template>
              <template v-slot:item.date="{ item }">
                <div>{{ $formatDate(item.date) }}</div>
              </template>

              <!-- <template v-slot:item.action="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="
                            $router.push(
                              '/arbeitsnachweis/view/' + id + '/' + item.id
                            )
                          "
                          fab
                          small
                          color="primary"
                        >
                          <i class="material-icons">playlist_add_check</i>
                        </v-btn>
                      </template>
                      <span>Arbeitsnachweis bearbeiten</span>
                    </v-tooltip>
                  </template> -->
            </v-data-table>
            <hr />
            <v-flex sm12 md6 lg4 xl3 offset-md6 offset-lg8 offset-xl9 v-if="showPricesToRole">
              <v-simple-table style="background-color: gainsboro">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <b>Summe netto</b>
                      </td>
                      <td class="text-center">
                        {{ $formatCurrency(summaryNetPrice) }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>MwSt. {{ companyconfig.mwst }}%</b>
                      </td>
                      <td class="text-center">
                        {{
            $formatCurrency(
              (summaryNetPrice * companyconfig.mwst) / 100
            )
          }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Gesamtbetrag</b>
                      </td>
                      <td class="text-center bold">
                        {{
              $formatCurrency(
                (summaryNetPrice * companyconfig.mwst) / 100 +
                summaryNetPrice
              )
            }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="invoiceDialog = false">Abbrechen</v-btn>
            <v-btn @click="createBilling()" color="primary">
              Rechnung erstellen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="confirm" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Löschen</v-card-title>
          <v-card-text v-if="deleteMode == 'an'">
            Sind sie sicher, dass Sie den Arbeitsnachweis
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-text v-if="deleteMode == 'doc'">
            Sind sie sicher, dass Sie das Dokument
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteItem()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="missingDialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Offene Wartungen</v-card-title>
          <v-card-text>
            Sie haben noch Wartungen, die noch nicht abgeschlossen sind. <br>
            Sind sie sicher, dass Sie den Auftrag dennoch abschliessen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success"  @click="changeStatus(3)">Abschliessen</v-btn>
            <v-btn color="error" text @click="missingDialog = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      confirm: false,
      missingDialog: false,
      deleteName: "",
      deleteId: "",
      deleteMode: "",
      deletePath: "",
      menu: false,
      showCustomersToRole: false,
      standorte: [],
      customers: [],
      users: [],
      leiters: [],
      selected: [],
      assignmentClosed: false,
      singleSelect: false,
      arbeitsnachweise: [],
      standorte: [],
      maengel: [],
      wartungen: [],
      info: {},
      tab: "",
      invoiceDialog: false,
      summaryNetPrice: 0,
      documents: [],
      summaryitems: [],
      docs: [],
      showPricesToRole: false,
      stati: [
        {
          id: "0",
          text: "Erstellt",
        },
        {
          id: "1",
          text: "In Bearbeitung",
        },
        {
          id: "2",
          text: "Übermittelt",
        },
        {
          id: "3",
          text: "Abgeschlossen",
        },
      ],
      newAssignment: {
        date: "",
        info: "",
        assignmentnumber: "",
        customerid: "",
        standortid: "",
        userids: "",
        usernames: "",
        comment: "",
      },
      newStandort: {
        name: "",
        contactperson: "",
        email: "",
        phone: "",
        street: "",
        houseno: "",
        zip: "",
        city: "",
      },
      headersdocuments: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "path",
        },
        {
          text: "Hochgeladen von",
          align: "left",
          value: "creator",
        },
        {
          text: "Hochgeladen am",
          align: "left",
          value: "created_at",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      headerssummary: [
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "text",
        },
        {
          text: "Datum",
          align: "left",
          sortable: false,
          value: "date",
        },
        {
          text: "Leistung",
          sortable: false,
          align: "left",
          value: "items",
        },
        /*  {
          text: "Material",
          sortable: false,
          value: "materialprice",
        },
        {
          text: "Arbeitsstunden",
          sortable: false,
          value: "hours",
        },
        {
          text: "Arbeitsstundenrechung",
          sortable: false,
          value: "hoursprice",
        }, */
        {
          text: "Preis",
          sortable: false,
          align: "left",
          value: "price",
        },
        /*  {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        }, */
      ],
      headersarbeitsnachweise: [
        {
          text: "Arbeitsnachweisnummer",
          align: "left",
          value: "number",
        },
        {
          text: "Betreff",
          sortable: false,
          value: "subject",
        },
        {
          text: "Typ",
          sortable: false,
          value: "type",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "Erstellt von / am",
          sortable: false,
          value: "created",
        },
        {
          text: "Aktualisiert von / am",
          sortable: false,
          value: "updated",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      /* headersmaengel: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Anlagenbezeichnung",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Gebäude",
          align: "left",
          sortable: false,
          value: "gebaeude",
        },
        {
          text: "Standort",
          align: "left",
          sortable: false,
          value: "standortname",
        },
        {
          text: "Typ",
          align: "left",
          sortable: false,
          value: "typ",
        },
        {
          text: "Seriennummer",
          align: "left",
          sortable: false,
          value: "seriennummer",
        },
        {
          text: "Baujahr",
          align: "left",
          sortable: false,
          value: "baujahr",
        },
        {
          text: "Bemerkung",
          align: "left",
          sortable: false,
          value: "comment",
        },
        {
          text: "Zeitstempel",
          align: "left",
          sortable: false,
          value: "timestamps",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ], */
      headerswartungen: [
        {
          text: "Wartungsblock Name",
          align: "left",
          value: "name",
        },
        {
          text: "Wartung finalisiert am",
          value: "finalized",
        },
        {
          text: "Dauer",
          value: "duration",
        },
        {
          text: "Wartungszyklus",
          value: "cycle",
        },
        {
          text: "Preis",
          value: "price",
        },
        {
          text: "Service-Techniker",
          value: "usernames",
        },
        {
          text: "Status",
          value: "status"
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action",
        },
      ],
      rules: {
        required: (value) => !!value || "Dieses Feld ist erforderlich.",
        requiredarray: (value) =>
          !!value.length || "Dieses Feld ist Erforderlich.",
      },
    };
  },
  watch: {
    selected() {
      this.calcInvoice();
    },
  },
  mounted: function () {
    this.getAssignment();
    const companyconfig = (this.companyconfig =
      this.$store.getters.companyconfig);
    this.showCustomersToRole =
      companyconfig &&
      companyconfig.showCustomersToRoles &&
      companyconfig.showCustomersToRoles.includes(this.role);
    this.showPricesToRole =
      companyconfig &&
      companyconfig.showPricesToRoles &&
      companyconfig.showPricesToRoles.includes(this.role);
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    role() {
      return this.$store.getters.user.role;
    },
    userid() {
      return this.$store.getters.user.id;
    },
    online() {
      return this.$store.getters.online;
    },
  },
  methods: {
    printNachweis(id, number) {
      let formData = new FormData();
      formData.append("id", id);
      var self = this;
      this.$http({
        method: "post",
        url: "printNachweis.php",
        data: formData,
        responseType: "blob",
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          /*  if (response.data && response.data.success && response.data.file) {
            const url =
              this.$http.defaults.serverURL +
              "/print/prints/" +
              response.data.file;
            window.open(url, "_blank");
          } else {
            const msg =
              "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          } */
          if (response.status === 200 && response.data) {
            const blobUrl = URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = blobUrl;
            link.download = "Arbeitsnachweis_" + number + ".pdf";
            link.type = "application/pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
            const msg = "Der Druck wurde erfolgreich erstellt.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
          const color = "error";
          self.$store.dispatch("snackbar", { msg, color });
        });
    },
    changeCustomer() {
      const id = this.info && this.info.customerid;
      if (id) {
        this.getStandorte(id);
      }
    },
    handleClick(item) {
      if (this.role == "worker") {
        this.$router.push(
          "/wartungsdetails/execute/" + item.wid + "/" + item.id
        );
      } else {
        this.$router.push("/wartungsdetails/view/" + item.wid + "/" + item.id);
      }
    },

    handleClick2(item) {
      if (this.role === "worker" || item.created_by === this.userid) {
        this.$router.push(
          "/arbeitsnachweis/execute/" + this.id + "/" + item.id
        );
      } else {
        this.$router.push("/arbeitsnachweis/view/" + this.id + "/" + item.id);
      }
    },
    checkStatus() {
      let hasMissing = false;
      this.wartungen.forEach(w => {
        if (w.status != "3") {
          hasMissing = true;
        }
      });
      if (hasMissing) {
        this.missingDialog = true;
      } else {
        this.changeStatus(3);
      }
    },
    changeStatus(status) {
      const id = this.id;
      let formData = new FormData();
      if (!id) {
        alert("Es ist ein fehler aufgetretten");
        return;
      }
      formData.append("id", id);
      formData.append("status", status);
      this.$http({
        method: "post",
        url: "changeAssignmentStatus.php",
        data: formData,
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            const msg = "Der Auftrag wurde erfolgreich abgeschlossen";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.$router.push("/assignments");
          } else {
            const msg =
              "Die Auftrag konnte nicht gespeichtert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          const msg =
            "Die Auftrag konnte nicht gespeichtert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    calcInvoice() {
      const selected = this.selected;
      let total = 0;
      selected.forEach((item) => {
        if (item.price) {
          total += parseFloat(item.price);
        }
      });
      this.summaryNetPrice = total;
    },
    getStandorte(id) {
      let formData = new FormData();
      formData.append("id", id);
      this.$http({
        method: "post",
        url: "getStandorte.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.standorte = response.data.data;
            this.info.standortid = null;
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
        });
    },
    getAssignment() {
      var self = this;
      let formData = new FormData();
      formData.append("id", this.id);
      var self = this;
      this.$http({
        method: "post",
        url: "getAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success) {
            if (response.data.info) {
              this.info = response.data.info;
              this.assignmentClosed = parseInt(this.info.status) >= 2 && this.role == 'worker';
              this.enrichInfos();
            }
            if (response.data.users) {
              this.users = response.data.users;
            }
            if (response.data.leiters) {
              this.leiters = response.data.leiters;
            }
            if (response.data.customers) {
              this.customers = response.data.customers;
            }
            if (response.data.standorte) {
              this.standorte = response.data.standorte;
            }
            if (response.data.arbeitsnachweise) {
              this.arbeitsnachweise = response.data.arbeitsnachweise;
            }
            if (response.data.wartungen) {
              this.wartungen = response.data.wartungen;
            }
            if (response.data.documents) {
              self.documents = response.data.documents;
            }
            this.createSummary();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    enrichInfos() {
      let ids;
      const item = this.info;
      if (item.userids) {
        if (Array.isArray(item.userids)) {
          ids = item.userids;
        } else {
          ids = JSON.parse(item.userids);
        }
      }
      ids = ids.map((id) => {
        return id.toString();
      });
      this.info["userids"] = ids;
      let plids;
      if (item.plids) {
        if (Array.isArray(item.plids)) {
          plids = item.plids;
        } else {
          plids = JSON.parse(item.plids);
        }
        plids = plids.map((id) => {
          return id.toString();
        });
        this.info.plids = plids;
      }
    },
    uploadDocs() {
      this.$uploadDocs(this.docs, this.id, "assignment", "company")
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.files) {
            const username = this.$store.getters.user.name;
            const userid = this.$store.getters.user.id;
            response.data.files.forEach((file) => {
              this.documents.push({
                id: file.id,
                path: file.path,
                filename: file.filename,
                created_at: this.$formatDateTimeISO(new Date()),
                created_by: userid,
                creator: username,
              });
            });
            this.docs = [];
            const msg = "Dokumente wurden erfolgreich hochgeladen.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Dokumente konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          this.$router.push("/");
        });
    },
    createBilling() {
      alert("Die Funktion muss noch implementiert werden.");
    },
    createSummary() {
      const an = this.arbeitsnachweise;
      const summaryitems = [];
      let summaryNetPrice = 0;
      an.forEach((a, index) => {
        let material = [];
        let materialprice = 0;
        let hours = [];
        let hoursprice = 0;
        let price = 0;
        if (a.material) {
          const mat = JSON.parse(a.material);
          if (mat && mat.length) {
            mat.forEach((m) => {
              material.push(m.count + " x " + m.name);
              if (m.sumprice) {
                materialprice += m.sumprice;
                price += m.sumprice;
                summaryNetPrice += parseFloat(m.sumprice);
              }
            });
          }
        }
        if (a.hours) {
          const hour = JSON.parse(a.hours);
          if (hour && hour.length) {
            hour.forEach((h) => {
              hours.push(h.count + " x " + h.product);
              if (h.sumprice) {
                hoursprice += h.sumprice;
                price += h.sumprice;
                summaryNetPrice += parseFloat(h.sumprice);
              }
            });
          }
        }
        summaryitems.push({
          text: "Arbeitsnachweis: " + a.number,
          date: a.created_at,
          id: index,
          items: material.join("<br>") + "<br>" + hours.join("<br>"),
          //materialprice: materialprice,
          //hours: hours.join("<br>"),
          //hoursprice: hoursprice,
          price: price,
        });
      });
      this.wartungen.forEach((w, i) => {
        summaryitems.push({
          text: "Wartung: " + w.name,
          date: w.finalized,
          id: "w_" + i,
          items: "Wartungsdurchführung",
          price: w.price,
        });
        summaryNetPrice += parseFloat(w.price);
      });
      this.summaryitems = summaryitems;
      //  this.summaryNetPrice = summaryNetPrice;
    },
    updateData() {
      if (this.$refs.form.validate()) {
        let info = JSON.parse(JSON.stringify(this.info));
        const userids = info["userids"];
        let usernames = [];
        let ids = [];
        if (userids) {
          userids.forEach((id) => {
            const user = this.users.find((u) => {
              return u.id === id;
            });
            if (user && user.name && user.id) {
              usernames.push(user.name);
              ids.push(parseInt(user.id));
            }
          });
        }
        info["usernames"] = usernames.join(", ");
        info["userids"] = ids;

        const plids = info["plids"];
        let plnames = [];
        let pids = [];
        if (plids) {
          plids.forEach((id) => {
            const pl = this.leiters.find((u) => {
              return u.id === id;
            });
            if (pl && pl.name && pl.id) {
              plnames.push(pl.name);
              pids.push(parseInt(pl.id));
            }
          });
        }
        info["plnames"] = plnames.join(", ");
        info["plids"] = pids;
        const date = Math.round(new Date(info.date).getTime() / 1000);
        info["time"] = date;
        let formData = new FormData();
        formData.append("assignment", JSON.stringify(info));
        formData.append("edit", true);
        this.$http({
          method: "post",
          url: "saveAssignment.php",
          data: formData,
        })
          .then((response) => {
            if (
              response.data &&
              response.data.success === false &&
              response.data.missingToken
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (
              response.data &&
              response.data.success === false &&
              response.data.authorization
            ) {
              const msg =
                "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
              this.confirm = false;
              return;
            }
            if (response.data && response.data.success) {
              let msg = "";
              msg = "Der Auftrag wurde erfolgreich aktualisiert";
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
            } else {
              const msg =
                "Der Auftrag konnten nicht aktualisiert werden. Bitte versuchen Sie es erneut.";
              const color = "error";
              this.$store.dispatch("snackbar", { msg, color });
            }
          })
          .catch((error) => {
            const response = error.response.data;
            if (
              response.status === 500 &&
              response.message === "NoLoggedInUser"
            ) {
              let axios = this.$http;
              this.$store
                .dispatch("logout", { axios })
                .then((resp) => {
                  this.$router.push("/login");
                })
                .catch((err) => {
                  console.log(err);
                });
              return;
            }
            this.$router.push("/");
          });
      } else {
        const msg =
          "Bitte füllen Sie alle Daten korrekt aus, bevor Sie speichern wollen.";
        const color = "error";
        this.$store.dispatch("snackbar", { msg, color });
      }
    },
    confirmDeleteItem(id, name, mode) {
      this.deleteName = name;
      this.deleteId = id;
      this.deleteMode = mode;
      this.confirm = true;
    },
    deleteItem() {
      let formData = new FormData();
      formData.append("id", this.deleteId);
      let url;
      if (this.deleteMode === "an") {
        url = "deleteArbeitsnachweis.php";
      }
      if (this.deleteMode === "block") {
        url = "deleteBlock.php";
      }
      if (this.deleteMode === "standort") {
        url = "deleteStandort.php";
      }
      if (this.deleteMode === "doc") {
        url = "deleteDocument.php";
        formData.append("path", this.deletePath);
      }
      if (!url) {
        return;
      }
      this.$http({
        method: "post",
        url: url,
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            let msg;
            if (this.deleteMode === "an") {
              this.arbeitsnachweise.forEach((a, i) => {
                if (a.id === this.deleteId) {
                  this.arbeitsnachweise.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.confirm = false;
                }
              });
              msg = "Der Arbeitsnachweis wurde erfolgreich gelöscht.";
            }
            if (this.deleteMode === "standort") {
              this.standorte.forEach((a, i) => {
                if (a.id === this.deleteId) {
                  this.standorte.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.confirm = false;
                }
              });
            }
            if (this.deleteMode === "doc") {
              this.documents.forEach((a, i) => {
                if (a.id === this.deleteId) {
                  this.documents.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.deletePath = "";
                  this.confirm = false;
                }
              });
              msg = "Das Dokuemnt wurde erfolgreich gelöscht.";
            }
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          error;
        });
    },
  },
};
</script>
